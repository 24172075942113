import styled from "@emotion/styled";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Divider,
  FormControl,
  FormLabel,
} from "@mui/material";
import { Link } from "react-router-dom";

const DialogHeader = styled(DialogTitle)`
  background: linear-gradient(to right, #ff6a00, #ee0979);
`;

const DialogBody = styled(DialogContent)`
  color: #f0f8ff;
`;

type LoginFormProps = {
  open: boolean;
  handleClose: () => void;
};

const LoginForm = ({ open, handleClose }: LoginFormProps) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          margin: 0,
          padding: 0,
          background: "black",
          maxWidth: { xs: "90%" },
        },
      }}
    >
      <DialogHeader>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>Login</span>
          <Button variant="contained" color="info">
            Sign UP
          </Button>
        </div>
      </DialogHeader>
      <Divider />
      <DialogContent>
        <TextField
          focused
          inputProps={{ style: { color: "#f0f8ff" } }}
          margin="dense"
          id="name"
          label="User Name"
          type="text"
          fullWidth
          variant="standard"
        />
        <TextField
          focused
          inputProps={{ style: { color: "#f0f8ff" } }}
          margin="dense"
          id="password"
          label="Password"
          type="password"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginForm;
