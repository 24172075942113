import { useState } from "react";
import Slides from "../components/Slides";
import Testimonials from "../components/Testimonials";
import Title from "../components/Title";
import About from "./About";
import Contact from "./Contact";
import LoginForm from "./LoginForm";

const Home = () => {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const toggleLoginForm = () => {
    setShowLoginForm(!showLoginForm);
  };

  return (
    <>
      <div style={{ background: "black" }}>
        <Title onGettingStartedClick={toggleLoginForm} />
        <Slides />
        <About />
        <Testimonials />
        <Contact />
        <LoginForm open={showLoginForm} handleClose={toggleLoginForm} />
        {/* <div>social media links</div>
      <div>mobile friendly</div> */}
      </div>
    </>
  );
};

export default Home;
