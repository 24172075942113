import { Grid, Typography, Paper, styled, useMediaQuery } from "@mui/material";
import { ContentTitle } from "../components/ContentTitle";
import { ReactElement } from "react";
import theme from "../theme";

const ContentBody = styled(Typography)({
  fontFamily: `"Segoe UI", Roboto, "Helvetica N"`,
  color: "#7dc4e8",
  textAlign: "justify",
  lineHeight: 1.2,
  ":not(:last-child)": {
    marginBottom: "0.6em",
  },
});

const ContentFooter = styled(Typography)({
  fontFamily: `"Helvetica N"`,
  display: "inline-flex",
  flexDirection: "column",
  fontWeight: 800,
  color: "cornsilk",
  fontSize: "1.3em",
  letterSpacing: "1.2px",
  wordSpacing: "3px",
  paddingTop: "0.25em",
  lineHeight: 1,
  margin: "0.1em 0",
});

const About = () => {
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid
      container
      spacing={2}
      sx={{
        marginTop: "0.5em",
      }}
      alignItems="center"
      justifyContent="space-evenly"
    >
      <Grid item xl={8} lg={7} md={6}>
        <article style={{ padding: "1em 1em 0 1em" }}>
          <ContentBody
            sx={{ fontSize: { lg: "1.4em", md: "1.2em", xs: "1em" } }}
          >
            At <strong>"MUZIIK KATTA"</strong>, whether you are a beginner or an
            experienced musician looking to refine your craft, we offer a
            comprehensive range of courses tailored to meet your specific needs.
          </ContentBody>
          <ContentBody
            sx={{ fontSize: { lg: "1.4em", md: "1.2em", xs: "1em" } }}
          >
            Also, we are proud to be the most trusted and the only destination
            for buying all genuine and original branded musical instruments in
            whole Kharadi, Viman Nagar, Wagholi and entire East Pune Area.
          </ContentBody>
          <ContentBody
            sx={{ fontSize: { lg: "1.4em", md: "1.2em", xs: "1em" } }}
          >
            Join us at <strong>"MUZIIK KATTA"</strong> to unleash your potential
            and embrace your passion for the music.
          </ContentBody>
          {!isMediumScreen && (
            <ContentFooter
              sx={{
                fontSize: {
                  lg: "1.1em",
                  md: "1em",
                  xs: "0.9em",
                },
              }}
            >
              <span>Sincerely,</span>
              <span>Dhaval Mulay</span>
              <span>Founder & Lead Instructor</span>
              <span>(Trinity Certified)</span>
            </ContentFooter>
          )}
        </article>
      </Grid>
      <Grid item xl={4} lg={5} md={6} xs={11}>
        <Paper sx={{ padding: 0, background: "darkgrey" }} elevation={3}>
          <img
            style={{
              width: "-webkit-fill-available",
              padding: "3px 3px 0 3px",
            }}
            src="/dm_profile.jpg"
            alt="DP"
          ></img>
        </Paper>
        {isMediumScreen && (
          <ContentFooter
            sx={{
              fontSize: {
                lg: "1.1em",
                md: "1em",
                xs: "0.9em",
              },
              mb: "0.8em",
            }}
          >
            <span>Sincerely,</span>
            <span>Dhaval Mulay</span>
            <span>Founder & Lead Instructor</span>
            <span>(Trinity Certified)</span>
          </ContentFooter>
        )}
      </Grid>
    </Grid>
  );
};

export default About;
