import styled from "@emotion/styled";
import { Button, Grid, Typography } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const AppTitle = styled(Typography)`
  font-size: 2.5em;
  font-weight: 600;
  background: linear-gradient(
    180deg,
    rgba(223, 176, 56, 1) 20%,
    rgba(207, 83, 63, 1) 60%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const TitleContainer = styled(Grid)`
  background: black;
  position: sticky;
  top: 0;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
`;

// background: linear-gradient(180deg, rgba(223,176,56,1) 40%, rgba(207,83,63,1) 89%);

type TitleProps = {
  onGettingStartedClick: () => void;
};

const Title = ({ onGettingStartedClick }: TitleProps) => {
  return (
    <TitleContainer container sx={{ padding: { lg: "0 1em", xs: "0 0.5em" } }}>
      <Grid item>
        <img src="/mk-logo.jpg" alt="app-logo" style={{ width: 75 }} />
      </Grid>
      <Grid
        item
        md={8}
        sx={{
          display: { xs: "none", lg: "block" },
          textAlign: "center",
        }}
      >
        <AppTitle>Muziik Katta</AppTitle>
      </Grid>
      <Grid item>
        <Button
          sx={{ borderRadius: "50px" }}
          variant="outlined"
          color="warning"
          size="small"
          onClick={onGettingStartedClick}
        >
          LET'S ROCK{" "}
          <KeyboardDoubleArrowRightIcon sx={{ marginLeft: { lg: "1em" } }} />
        </Button>
      </Grid>
    </TitleContainer>
  );
};

export default Title;
