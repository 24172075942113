import Carousel from "react-material-ui-carousel";
import { Card, CardMedia, useTheme } from "@mui/material";
import list from "../img/static/carousel/list.json";
import useMediaQuery from "@mui/material/useMediaQuery";

function Slides(props: any) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const imgSize = isMobile ? "small" : "large";
  return (
    <Carousel
      autoPlay
      stopAutoPlayOnHover={false}
      interval={2200}
      animation="slide"
    >
      {list.files.map((img) => (
        <Card key={img} sx={{ padding: 0, margin: 0, borderRadius: 0 }}>
          <CardMedia
            component="img"
            sx={{ height: { xl: 500, lg: 400 }, minHeight: 305 }}
            image={require("../img/static/carousel/" + imgSize + "/" + img)}
            alt="carousel image"
          />
        </Card>
      ))}
    </Carousel>
  );
}

export default Slides;
