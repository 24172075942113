import { Divider, Grid, Icon, Typography, styled } from "@mui/material";
import { LocationOn, Call } from "@mui/icons-material";

const SocialMediaIcon = styled(Icon)({
  height: "50px",
  width: "50px",
  ">img": {
    display: "flex",
    height: "inherit",
    width: "inherit",
  },
});

const AddressContent = styled(Typography)({
  fontFamily: `"Segoe UI", Roboto, "Helvetica N"`,
  display: "flex",
  fontWeight: 400,
  fontSize: "14px",
  ":not(:first-of-type)": {
    paddingLeft: "7px",
  },
});

const AddressContentClickable = styled(AddressContent)({
  fontWeight: 600,
  cursor: "pointer",
  marginTop: "0.5em",
});

const Contact = () => {
  return (
    <>
      <Divider
        sx={{ display: { xs: "none", md: "block", borderColor: "lightgrey" } }}
      />
      <Grid
        container
        alignItems="center"
        sx={{ background: "white" }}
        justifyContent="space-evenly"
        direction="row-reverse"
      >
        <Grid
          item
          md={3}
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            paddingTop: "0.5em",
          }}
        >
          <div style={{ maxWidth: "180px", paddingTop: "0.5em" }}>
            <a
              href="https://goo.gl/maps/jvRFum94dmmSb3op8"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{
                  width: "-webkit-fill-available",
                  padding: "0 20px",
                }}
                src="/mk-location.jpg"
                alt="DP"
              ></img>
            </a>
          </div>
        </Grid>
        <Grid
          item
          md={3}
          xs={6}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <div>
            <AddressContentClickable
              onClick={() =>
                window.open("https://goo.gl/maps/jvRFum94dmmSb3op8")
              }
            >
              <LocationOn />
              MUZIIK KATTA
            </AddressContentClickable>
            <AddressContent>Plot 17, Parashar Housing Soc</AddressContent>
            <AddressContent>Near Down Town Langstone,</AddressContent>
            <AddressContent>Kharadi, Pune, </AddressContent>
            <AddressContent>Maharashtra 411014</AddressContent>

            <AddressContentClickable
              onClick={() => window.open("tel:+919712926885", "_self")}
            >
              <Call />
              +91 97129 26885
            </AddressContentClickable>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <Divider sx={{ display: { md: "none" }, margin: "1em 0" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <a
              href="https://www.instagram.com/p/CvhvuwQuaQ_/"
              target="_blank"
              rel="noreferrer"
            >
              <SocialMediaIcon>
                <img alt="instagram" src="/instagram-logo-outlined.svg" />
              </SocialMediaIcon>
            </a>
            <a
              href="https://www.youtube.com/watch?v=oHwYwGVNgpM&t=24s"
              target="_blank"
              rel="noreferrer"
            >
              <SocialMediaIcon>
                <img alt="youtube" src="/youtube-logo.svg" />
              </SocialMediaIcon>
            </a>
            <a
              href="https://wa.me/qr/O4EZWA7UD56EO1"
              target="_blank"
              rel="noreferrer"
            >
              <SocialMediaIcon>
                <img alt="whatsapp" src="/whatsapp-logo.svg" />
              </SocialMediaIcon>
            </a>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Contact;
