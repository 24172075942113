import Carousel from "react-material-ui-carousel";
import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import list from "../img/static/carousel/list.json";
import { ContentTitle } from "./ContentTitle";
import { stringAvatar } from "../utils/common";
import { useState } from "react";

function Testimonials(props: any) {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const [isMuted, setIsMuted] = useState(true);

  return (
    <Grid
      container
      sx={{
        /* backgroundImage: `url(${"testimonials.jpg"})`,
        backgroundSize: "cover", */
        background: "#bdbddb",
        padding: "0.5em",
        justifyContent: "center",
      }}
    >
      <Grid sx={{ marginTop: "1em" }} item xl={5} md={7} xs={11}>
        <Carousel
          autoPlay={false}
          navButtonsAlwaysVisible={!isMobile}
          animation="slide"
        >
          {list.testimonials.map((testimonial) => (
            <Card sx={{ padding: "1em", paddingBottom: 0, margin: "auto" }}>
              <CardMedia
                height="fit-content"
                style={{ maxHeight: isMobile ? 250 : 450 }}
                component="video"
                autoPlay
                onTouchStart={() => setIsMuted(false)}
                controls
                muted={isMuted}
                src={testimonial.video}
                onClick={() => setIsMuted(false)}
              />
              <CardContent sx={{ margin: "auto" }}>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  gutterBottom
                  variant="h6"
                  component="div"
                >
                  <Avatar {...stringAvatar(testimonial.name)} />
                  {testimonial.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ textAlign: "justify" }}
                >
                  {testimonial.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Carousel>
      </Grid>
    </Grid>
  );
}

export default Testimonials;
